const API_HELPER = {
  BASE: process.env.REACT_APP_API_DOMAIN,
  URL: {
    login: 'auth/staff/login/',
    sendCode: 'auth/staff/password/reset/',
    verifyEmail: 'auth/staff/password/verify-email/',
    resetPassword: 'auth/staff/password/reset/confirm/',
    staffUserList: 'auth/staff_user/',
    setPassword: 'auth/staff/password/update/',
    sendInvite: 'auth/send_invite/',
    userRoleList: 'auth/staff_role/',
    deleteUser: 'auth/staff_user/',
    rejectInvite: 'auth/staff_user/reject/',
    templateFeature: 'notifications/template/',
    templateDropdownList: 'notifications/template/dropdown/',
    advertisementFeature: 'notifications/advertisement/',
    staffInviteValidity: 'auth/staff_invite_validity/',
    googleTranslateAPI: 'https://translation.googleapis.com/language/translate/v2?key=' + process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY,
    pendingCounts: 'notifications/advertisement/pending_counts/',
    birthdayExist: 'notifications/advertisement/birthday_exists/',
  },
}
export default API_HELPER
