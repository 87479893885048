import { createContext, useEffect, useState } from 'react'
import { getPendingCount } from './services/announcement-services'

export const SkandikaContextData = createContext(null)

function Context({ children }) {
  const [contextData, setContextData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchPendingCountData = async () => {
    setIsLoading(true)

    try {
      const response = await getPendingCount()
      if (response.success) {
        setContextData({ ...contextData, pendingCountData: response.data })
      }
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAnnouncementExist = (is_listexist) => {
    setContextData({ ...contextData, isAnnouncementExist: is_listexist })
  }  

  const contextValue = {
    contextData,
    isLoading,
    error,
    refetchPendingCountData: fetchPendingCountData, // You can use refetchData to trigger a manual refresh
    handleAnnouncementExist
  }
  return <SkandikaContextData.Provider value={contextValue}>{children}</SkandikaContextData.Provider>
}

export default Context
