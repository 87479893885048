import React, { useState, useEffect } from 'react'
import { OutlinedInput, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import ENUMS from '../../constants/enum'

const SearchComponent = ({ setSearchString, searchString = '', placeholder = 'Search', postSearch = () => {}, postClear = () => {} }) => {
  const [searchStringValue, setSearchStringValue] = useState('')
  const handleSearchOnKeyDown = () => {
    setSearchString(searchStringValue)
    postSearch(searchStringValue)
  }

  const clearSearch = () => {
    setSearchString('')
    setSearchStringValue('')
    postClear()
  }

  useEffect(() => {
    if (!searchString.length) {
      setSearchStringValue('')
    }
  }, [searchString])

  return (
    <OutlinedInput
      placeholder={placeholder}
      size='small'
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon color='primary' sx={{ color: '#969c9c' }} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment className='pointerCursor' position='end' onClick={clearSearch}>
          {searchStringValue ? <CloseIcon color='primary' fontSize='small' /> : ''}
        </InputAdornment>
      }
      value={searchStringValue}
      onChange={e => setSearchStringValue(e.target.value)}
      onKeyDown={e => e.key === 'Enter' && handleSearchOnKeyDown()}
      sx={{ width: '300px' }}
    />
  )
}

export default SearchComponent
