import { Card, CardContent, CardHeader, Grid, Container, Divider, IconButton, Typography, CardActions, Box, Chip, Avatar, FormHelperText } from '@mui/material'
import Layout from '../layout/layout'
import { ArrowBack } from '@mui/icons-material'
import { useContext, useEffect, useRef, useState } from 'react'
import { RingSpinnerOverlay } from 'react-spinner-overlay'
import { useNavigate, useParams } from 'react-router-dom'
import ENUMS from '../../constants/enum'
import { FloatingButton, MinimalDatePicker, MinimalDateTimePicker, MinimalMultipleSelect, MinimalSingleSelect, MinimalTimePicker } from '../common/form-control'
import { DialogPrompt, LanguageContentCard } from '../common/other-component'
import enFlagImg from '../../assets/images/en_flag.png'
import duFlagImg from '../../assets/images/du_flag.png'
import frFlagImg from '../../assets/images/fr_flag.png'
import geFlagImg from '../../assets/images/ge_flag.png'
import itFlagImg from '../../assets/images/it_flag.png'
import spFlagImg from '../../assets/images/sp_flag.png'
import moment from 'moment'
import _ from 'lodash'
import { getTemplateDetailById, templateDropdownList } from '../../services/template-service'
import * as yup from 'yup'
import { validateSchema } from '../../helpers/schema-validation'
import { addAnnouncement, birthdayExist, getAnnouncementDetailById } from '../../services/announcement-services'
import { imageUrlToBase64, isValidFileType, setMultipleSelectedValue } from '../../helpers/common-helper'
import getUserRole from '../../helpers/getuser-role'
import { convertTimePartWithTimezone, convertUtcTimeToTimezone as convertUtcTimePartToTimezone } from '../../helpers/datetime-util'
import { SkandikaContextData } from '../../context'
import { Toast } from '../common/toast-utils'

const country_codes = [
  { label: 'EN', value: 'en', image_src: enFlagImg },
  // { label: 'DU', value: 'nl', image_src: duFlagImg },
  { label: 'FR', value: 'fr', image_src: frFlagImg },
  { label: 'GE', value: 'de', image_src: geFlagImg },
  // { label: 'IT', value: 'it', image_src: itFlagImg },
  // { label: 'SP', value: 'es', image_src: spFlagImg },
]
const defaultFormContent = [
  {
    //english
    language_code: 'en',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  // {
  //   //dutch
  //   language_code: 'nl',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
  {
    //french
    language_code: 'fr',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  {
    //german
    language_code: 'de',
    title: '',
    description: '',
    redirect_url: null,
    image: null,
    is_visible: false,
  },
  // {
  //   //italian
  //   language_code: 'it',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
  // {
  //   //spanish
  //   language_code: 'es',
  //   title: '',
  //   description: '',
  //   redirect_url: null,
  //   image: null,
  //   is_visible: false,
  // },
]
function AddAnnouncement() {
  const checkUserRole = new getUserRole()
  const titleCharLimit = 60
  const descriptionCharLimit = 400
  const urlParams = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [templateList, setTemplateList] = useState([])
  const [regionAndLanguageDetail, setRegionAndLanguageDetail] = useState({
    regions: [],
    repeat_mode: null,
    selected_language: [],
    template: null,
  })
  const [regionError, setRegionError] = useState({})
  const [pushNotificationDetail, setPushNotificationDetail] = useState({
    date: null,
    time: null,
    week: [],
    month: [],
  })
  const [pushNotificationError, setPushNotificationError] = useState({})
  const [formContent, setFormContent] = useState([...defaultFormContent])
  const [deErrors, setDeErrors] = useState({})
  const [enErrors, setEnErrors] = useState({})
  const [itErrors, setItErrors] = useState({})
  const [frErrors, setFrErrors] = useState({})
  const [nlErrors, setNlErrors] = useState({})
  const [esErrors, setEsErrors] = useState({})
  const fileUploadInputRefs = {
    deInputRefs: useRef(),
    enInputRefs: useRef(),
    itInputRefs: useRef(),
    frInputRefs: useRef(),
    nlInputRefs: useRef(),
    esInputRefs: useRef(),
  }
  const [targetAudience, setTargetAudience] = useState(null)
  const [targetError, setTargetError] = useState({})
  const [lifeSpanDetail, setLifeSpanDetail] = useState({
    teaser_date: null,
    start_datetime: null,
    end_datetime: null,
  })
  const [lifeSpanError, setLifeSpanError] = useState({})
  const [notifyBeforeError, setNotifyBeforeError] = useState({})
  const [announcementId, setAnnouncementId] = useState(0)
  const [showBackClickDialogue, setShowBackClickDialogue] = useState(false)
  const currentDateTime = moment()
  const [isDataChanged, setIsDataChanged] = useState(false)
  const isViewOnly = window.location.pathname.includes('/view') ? true : false
  const { refetchPendingCountData } = useContext(SkandikaContextData)
  const [notifyBeforeDetail, setNotifyBeforeDetail] = useState({
    notify_before: null,
    birthday_notification_time: null,
  })
  const [repeatModeOptions, setRepeatModeOptions] = useState([...ENUMS.REPEATMODE_LIST])
  const [isRegionDisable, setIsRegionDisable] = useState(false)
  useEffect(() => {
    setIsChangesMade(false)
    setLoading(true)
    ;(async () => {
      try {
        if (!_.isEmpty(urlParams) && urlParams.id !== 0) {
          setLoading(true)
          const res = await getAnnouncementDetailById(urlParams.id)
          if (res.success) {
            if (!_.isEmpty(res.data)) {
              const responseData = res.data
              setAnnouncementId(responseData.id)
              const selectedRegions = setMultipleSelectedValue([...ENUMS.COUNTRY_LIST], responseData.region)
              setRegionAndLanguageDetail({
                regions: selectedRegions,
                repeat_mode: ENUMS.REPEATMODE_LIST.find(e => e.value === responseData.repeat_mode),
                selected_language: !_.isEmpty(responseData.contents) ? responseData.contents.map(e => e.language_code) : [],
              })
              if (!_.isNaN(responseData.repeat_mode) && responseData.repeat_mode !== ENUMS.REPEATE_MODE.BIRTHDAY) {
                await checkBirthdayExist()
              }
              if (!_.isNaN(responseData.repeat_mode) && responseData.repeat_mode === ENUMS.REPEATE_MODE.BIRTHDAY) {
                setIsRegionDisable(true)
              }
              if (!_.isEmpty(responseData.contents)) {
                const contents = await Promise.all(
                  responseData.contents.map(async item => {
                    let imageBase64 = item.image
                    if (!_.isEmpty(item.image)) {
                      imageBase64 = await imageUrlToBase64(item.image)
                    }
                    return {
                      ...item,
                      image: imageBase64,
                      is_visible: true,
                    }
                  })
                )
                let actualFormContent = defaultFormContent.map(item => {
                  if (contents.some(e => e.language_code === item.language_code)) {
                    item = contents.find(e => e.language_code === item.language_code)
                  }
                  return item
                })
                setFormContent(actualFormContent)
              }
              if ((responseData.repeat_mode === ENUMS.REPEATE_MODE.ONE_TIME || responseData.repeat_mode === ENUMS.REPEATE_MODE.YEARLY) && !_.isEmpty(responseData.repeat_mode_values.datetime)) {
                const userTimeZone = moment.tz.guess()
                const localTime = moment(responseData.repeat_mode_values.datetime).clone().tz(userTimeZone)
                setPushNotificationDetail({
                  ...pushNotificationDetail,
                  date: localTime,
                  time: localTime,
                })
              } else if (responseData.repeat_mode === ENUMS.REPEATE_MODE.DAILY && !_.isEmpty(responseData.repeat_mode_values.time)) {
                const todayDateTime = moment()
                const timezoneTimeString = convertUtcTimePartToTimezone(responseData.repeat_mode_values.time)
                const combinedDateTime = moment(`${todayDateTime.format('YYYY-MM-DD')} ${timezoneTimeString}`, 'YYYY-MM-DD HH:mm')
                setPushNotificationDetail({
                  ...pushNotificationDetail,
                  time: combinedDateTime,
                })
              } else if (responseData.repeat_mode === ENUMS.REPEATE_MODE.WEEKLY && !_.isEmpty(responseData.repeat_mode_values.time)) {
                const todayDateTime = moment()
                const timezoneTimeString = convertUtcTimePartToTimezone(responseData.repeat_mode_values.time)
                const combinedDateTime = moment(`${todayDateTime.format('YYYY-MM-DD')} ${timezoneTimeString}`, 'YYYY-MM-DD HH:mm')
                const selectedWeeks = setMultipleSelectedValue([...ENUMS.WEEK_LIST], responseData.repeat_mode_values.week)
                setPushNotificationDetail({
                  ...pushNotificationDetail,
                  time: combinedDateTime,
                  week: !_.isEmpty(responseData.repeat_mode_values) && !_.isEmpty(responseData.repeat_mode_values.week) ? selectedWeeks : [],
                })
              } else if (responseData.repeat_mode === ENUMS.REPEATE_MODE.MONTHLY && !_.isEmpty(responseData.repeat_mode_values.datetime)) {
                const userTimeZone = moment.tz.guess()
                const localTime = moment(responseData.repeat_mode_values.datetime).clone().tz(userTimeZone)
                const selectedMonths = setMultipleSelectedValue([...ENUMS.MONTH_LIST], responseData.repeat_mode_values.month)
                setPushNotificationDetail({
                  ...pushNotificationDetail,
                  date: localTime,
                  time: localTime,
                  month: !_.isEmpty(responseData.repeat_mode_values) && !_.isEmpty(responseData.repeat_mode_values.month) ? selectedMonths : [],
                })
              } else if (responseData.repeat_mode === ENUMS.REPEATE_MODE.BIRTHDAY && !_.isNaN(responseData.notify_before)) {
                const notifyBeforeDay = ENUMS.NOTIFYBEFORE_DAY_LIST.find(e => e.value === responseData.notify_before)
                const todayDateTime = moment()
                const timezoneTimeString = convertTimePartWithTimezone(responseData.birthday_notification_time)
                const combinedDateTime = moment(`${todayDateTime.format('YYYY-MM-DD')} ${timezoneTimeString}`, 'YYYY-MM-DD HH:mm')
                setNotifyBeforeDetail({ ...notifyBeforeDay, notify_before: notifyBeforeDay, birthday_notification_time: combinedDateTime })
              }

              if (!_.isEmpty(responseData.target_devices)) {
                const targetDevice = ENUMS.TARGET_AUDIENCE_LIST.find(e => e.value === responseData.target_devices)
                setTargetAudience(targetDevice)
              }
              setLifeSpanDetail({
                teaser_date: !_.isEmpty(responseData.teaser_date) ? moment(responseData.teaser_date) : null,
                start_datetime: !_.isEmpty(responseData.start_date) ? moment(responseData.start_date) : null,
                end_datetime: !_.isEmpty(responseData.end_date) ? moment(responseData.end_date) : null,
              })
            }
          } else {
            Toast.error(res.error_message)
          }
        } else {
          const res = await templateDropdownList()
          if (res.success) {
            let list_data = res.data ? res.data.map(e => ({ label: e.name, value: e.id })) : []
            list_data.unshift({ label: 'Select Template', value: 0 })
            setTemplateList(list_data)
          } else {
            setTemplateList([])
            Toast.error(res.error_message)
          }
          await checkBirthdayExist()
        }
      } catch (error) {
        console.log(error)
        Toast.error(error)
        setTemplateList([])
      } finally {
        setLoading(false)
      }
    })()
    const checkBirthdayExist = async () => {
      try {
        const res = await birthdayExist()
        if (res.success) {
          if (res.data && res.data.birthday_exists === true) {
            const options = ENUMS.REPEATMODE_LIST.filter(e => e.value !== ENUMS.REPEATE_MODE.BIRTHDAY)
            setRepeatModeOptions(options)
          }
        } else {
          Toast.error(res.error_message)
        }
      } catch (error) {
        Toast.error(error)
      }
    }
  }, [urlParams])

  const CardTitle = () => {
    return (
      <>
        <IconButton
          size='small'
          onClick={() => {
            if (isViewOnly) {
              setIsChangesMade(false)
              navigate('/announcements')
            } else if (isDataChanged) {
              setShowBackClickDialogue(true)
            } else {
              setIsChangesMade(false)
              navigate('/announcements')
            }
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography component='span' display='inline-flex' alignItems='center' fontWeight='600'>
          {!_.isEmpty(urlParams) && urlParams.id !== 0 ? (isViewOnly ? 'View Announcement' : 'Edit Announcement') : 'Add Announcement'}
        </Typography>
      </>
    )
  }

  const handleFileSelect = event => {
    setIsChangesMade(true)
    const file = event.target.files[0]
    const fileInputLangCode = event.target.id.substring(0, event.target.id.indexOf('.'))
    const stateImagePropertyName = event.target.id.split('.').pop()
    if (file && isValidFileType(file)) {
      if (event.target.id) {
        const reader = new FileReader()
        reader.onload = e => {
          if (file.size > 2 * 1024 * 1024) {
            const error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_SIZE }
            handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
            return
          }

          const base64 = e.target.result
          const img = new Image()
          img.src = base64

          img.onload = () => {
            const width = img.width
            const height = img.height
            const aspectRatio = width / height
            let error_message = null
            if (Math.abs(aspectRatio - 16 / 9) > 0.01) {
              error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_ASPECT_RATIO }
            } else {
              const updatedData = formContent.map(formValue => {
                if (formValue.language_code === fileInputLangCode) {
                  return {
                    ...formValue,
                    [stateImagePropertyName]: base64,
                  }
                } else return formValue
              })
              setFormContent(updatedData)
            }
            handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
          }
        }
        reader.readAsDataURL(file)
        event.target.value = null
      }
    } else if (file && !isValidFileType(file)) {
      const error_message = { error: true, msg: ENUMS.COMMON_MSG.INVALID_IMAGE_FILE_FORMAT }
      handleLanguageContentError(fileInputLangCode, stateImagePropertyName, error_message)
    }
  }

  const handleLanguageContentError = (language_code, property_name, error_message = null) => {
    if (language_code === 'de') {
      setDeErrors({ ...deErrors, [property_name]: error_message })
    } else if (language_code === 'en') {
      setEnErrors({ ...enErrors, [property_name]: error_message })
    } else if (language_code === 'it') {
      setItErrors({ ...itErrors, [property_name]: error_message })
    } else if (language_code === 'fr') {
      setFrErrors({ ...frErrors, [property_name]: error_message })
    } else if (language_code === 'nl') {
      setNlErrors({ ...nlErrors, [property_name]: error_message })
    } else if (language_code === 'es') {
      setEsErrors({ ...esErrors, [property_name]: error_message })
    }
  }
  const clearImage = language_code => {
    setIsChangesMade(true)
    const updatedData = formContent.map(formValue => {
      if (formValue.language_code === language_code) {
        return {
          ...formValue,
          image: null,
        }
      } else return formValue
    })
    setFormContent(updatedData)
  }

  const handleContentSelect = language_code => {
    setIsChangesMade(true)
    const updatedData = formContent.map(formValue => {
      if (formValue.language_code === language_code) {
        return {
          ...formValue,
          is_visible: !formValue.is_visible,
        }
      } else return formValue
    })

    setFormContent(updatedData)
    const selectedLanguage = updatedData.filter(e => e.is_visible === true).map(e => e.language_code)
    setRegionError({ ...regionError, selected_language: { error: false, msg: '' } })
    setRegionAndLanguageDetail({ ...regionAndLanguageDetail, selected_language: selectedLanguage })
  }

  const handleRegionAndLanguageInputChange = (name, opt) => {
    setIsChangesMade(true)
    setRegionAndLanguageDetail({ ...regionAndLanguageDetail, [name]: opt })
    if (name === 'repeat_mode') {
      const resetData = {
        date: null,
        time: null,
        week: [],
        month: [],
      }
      setPushNotificationDetail({ ...pushNotificationDetail, ...resetData })
      setPushNotificationError(true)
      if (opt?.value === ENUMS.REPEATE_MODE.BIRTHDAY) {
        //select all regions and make field disable
        const selectedRegions = [...ENUMS.COUNTRY_LIST]
        selectedRegions.unshift(ENUMS.SELECTALL_OPTION)
        setRegionAndLanguageDetail({ ...regionAndLanguageDetail, regions: selectedRegions, [name]: opt })
        setIsRegionDisable(true)
      } else {
        setIsRegionDisable(false)
      }
      const resetNotifyBefore = {
        notify_before: null,
        birthday_notification_time: null,
      }
      setNotifyBeforeDetail({ ...notifyBeforeDetail, resetNotifyBefore })
      setNotifyBeforeError(true)
    }
    if (name === 'template') {
      handleDetailOnTemplateChange(opt.value)
      setDeErrors(true)
      setNlErrors(true)
      setFrErrors(true)
      setItErrors(true)
      setEnErrors(true)
      setEsErrors(true)
    }
  }

  const handleInputChange = (e, language_code) => {
    setIsChangesMade(true)
    const { name, value } = e.target
    const updatedData = formContent.map(formValue => {
      if (formValue.language_code === language_code && ((name !== 'title' && name !== 'description') || (name === 'title' && value.length <= titleCharLimit) || (name === 'description' && value.length <= descriptionCharLimit))) {
        return {
          ...formValue,
          [name]: value,
        }
      } else return formValue
    })

    setFormContent(updatedData)
  }

  const handleInputFocus = (e, language_code) => {
    const { name } = e.target
    const error_message = { error: false, msg: '' }
    handleLanguageContentError(language_code, name, error_message)
  }

  const handlePushNotificationDetailChange = async (name, value) => {
    setIsChangesMade(true)
    if (name === 'date' || name === 'time') {
      setPushNotificationError({ ...pushNotificationError, [name]: { error: false, msg: '' } })
    }
    setPushNotificationDetail({ ...pushNotificationDetail, [name]: value })
  }

  const handleDetailOnTemplateChange = async id => {
    if (id !== 0) {
      setLoading(true)
      try {
        const res = await getTemplateDetailById(id)
        if (res.success) {
          if (res.data && res.data.contents.length) {
            const items = await Promise.all(
              formContent.map(async formValue => {
                const languageContentDetail = res.data.contents.find(e => e.language_code === formValue.language_code)
                if (!_.isEmpty(languageContentDetail)) {
                  let imageBase64 = languageContentDetail.image
                  if (!_.isEmpty(languageContentDetail.image)) {
                    imageBase64 = await imageUrlToBase64(languageContentDetail.image)
                  }
                  return {
                    title: languageContentDetail.title,
                    description: languageContentDetail.description,
                    redirect_url: languageContentDetail.redirect_url,
                    language_code: languageContentDetail.language_code,
                    image: imageBase64,
                    is_visible: formValue.is_visible,
                  }
                } else return formValue
              })
            )
            setFormContent(items)
          } else if (res.data && res.data.contents.length === 0) {
            //set default form content
            const updatedData = defaultFormContent.map(formValue => {
              const languageContentDetail = formContent.find(e => e.language_code === formValue.language_code)
              if (!_.isEmpty(languageContentDetail)) {
                return {
                  ...formValue,
                  is_visible: languageContentDetail.is_visible,
                }
              } else return formValue
            })

            setFormContent(updatedData)
          }
        } else {
          Toast.error(res.error_message)
        }
      } catch (error) {
        Toast.error(error)
        console.log(error)
      }
      setLoading(false)
    } else {
      const updatedData = defaultFormContent.map(formValue => {
        const languageContentDetail = formContent.find(e => e.language_code === formValue.language_code)
        if (!_.isEmpty(languageContentDetail)) {
          return {
            ...formValue,
            is_visible: languageContentDetail.is_visible,
          }
        } else return formValue
      })

      setFormContent(updatedData)
    }
  }

  const handleTargetChange = opt => {
    setIsChangesMade(true)
    setTargetAudience(opt)
  }

  const handleLifeSpanDetailChange = (name, value) => {
    setIsChangesMade(true)
    setLifeSpanError({ ...lifeSpanError, [name]: { error: false, msg: '' } })
    setLifeSpanDetail({ ...lifeSpanDetail, [name]: value })
  }

  const handleNotifyBeforeChange = (name, value) => {
    setIsChangesMade(true)
    if (name === 'birthday_notification_time') {
      setNotifyBeforeError({ ...notifyBeforeError, [name]: { error: false, msg: '' } })
    }
    setNotifyBeforeDetail({ ...notifyBeforeDetail, [name]: value })
  }

  const handleSaveOrReviewBtnClick = async (e, is_review = false) => {
    e.preventDefault()
    const isValid = await validateForm()
    if (isValid) {
      let repeatModeValues = {}
      if (!_.isEmpty(regionAndLanguageDetail.repeat_mode) && regionAndLanguageDetail.repeat_mode?.value !== ENUMS.REPEATE_MODE.BIRTHDAY) {
        if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.ONE_TIME || regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.YEARLY) {
          const datetime = moment(pushNotificationDetail.date.format('YYYY-MM-DD') + ' ' + pushNotificationDetail.time.format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
          repeatModeValues.datetime = moment.utc(datetime).format('YYYY-MM-DDTHH:mm:ss[Z]')
        } else if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.DAILY) {
          repeatModeValues.time = !_.isEmpty(pushNotificationDetail.time) ? moment.utc(pushNotificationDetail.time).format('HH:mm') : null
        }
        if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.WEEKLY) {
          repeatModeValues.week = pushNotificationDetail.week.length ? pushNotificationDetail.week.filter(e => e.value !== '*').map(e => e.value) : []
          repeatModeValues.time = !_.isEmpty(pushNotificationDetail.time) ? moment.utc(pushNotificationDetail.time).format('HH:mm') : null
        }
        if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.MONTHLY) {
          repeatModeValues.month = pushNotificationDetail.month.length ? pushNotificationDetail.month.filter(e => e.value !== '*').map(e => e.value) : []
          repeatModeValues.time = !_.isEmpty(pushNotificationDetail.time) ? moment.utc(pushNotificationDetail.time).format('HH:mm') : null
        }
      } else {
        repeatModeValues = null
      }

      let contentList = formContent
        .filter(e => e.is_visible === true)
        .map(element => {
          if (!_.isEmpty(element.title)) {
            element.title = element.title.trim()
          }
          if (!_.isEmpty(element.description)) {
            element.description = element.description.trim()
          }
          if (!_.isEmpty(element.redirect_url)) {
            element.redirect_url = element.redirect_url.trim()
          }
          return element
        })

      let payload = {
        start_date: !_.isEmpty(lifeSpanDetail.start_datetime) ? moment.utc(lifeSpanDetail.start_datetime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
        end_date: !_.isEmpty(lifeSpanDetail.end_datetime) ? moment.utc(lifeSpanDetail.end_datetime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
        teaser_date: !_.isEmpty(lifeSpanDetail.teaser_date) ? moment.utc(lifeSpanDetail.teaser_date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
        region: regionAndLanguageDetail.regions.length ? regionAndLanguageDetail.regions.filter(e => e.value !== '*').map(e => e.value) : [],
        repeat_mode: regionAndLanguageDetail.repeat_mode?.value,
        repeat_mode_values: repeatModeValues,
        target_devices: targetAudience?.value,
        contents: [...contentList],
        review: is_review,
      }
      if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.BIRTHDAY) {
        payload.notify_before = notifyBeforeDetail.notify_before?.value
        payload.birthday_notification_time = !_.isEmpty(notifyBeforeDetail.birthday_notification_time) ? moment(notifyBeforeDetail.birthday_notification_time).format('HH:mm') : null
      }
      if (announcementId !== 0) {
        payload.id = announcementId
      }
      setLoading(true)
      try {
        const res = await addAnnouncement(payload)
        if (res.success) {
          if (checkUserRole.isAdmin() || (checkUserRole.isStaff() && is_review === true)) {
            setIsChangesMade(false)
            navigate('/announcements')
            await refetchPendingCountData()
          } else if (checkUserRole.isStaff() && is_review === false && res.data.data) {
            setIsChangesMade(false)
            setAnnouncementId(res.data.data.id)
          }
          Toast.success(res.data.detail)
        } else {
          Toast.error(res.error_message)
        }
      } catch (error) {
        Toast.error(error)
        console.log(error)
      }
      setLoading(false)
    }
  }

  const validateForm = async () => {
    debugger
    //region block validation
    const region_schema = yup.object().shape({
      regions: yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      selected_language: yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      repeat_mode: yup.object().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    })
    const isRegionValid = await validateSchema(regionAndLanguageDetail, region_schema)
    setRegionError(isRegionValid)

    let isLanguageContentInValid = false
    if (formContent.length) {
      //content block validation
      formContent.forEach(async item => {
        if (item.is_visible) {
          const schema = yup.object().shape({
            title: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
            description: yup.string().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
            redirect_url: yup
              .string()
              .test('valid-url', ENUMS.COMMON_MSG.INVALID_URL, value => {
                if (!value) return true // Allow empty input
                return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value)
              })
              .nullable(true),
          })
          const isContentValid = await validateSchema(item, schema)
          if (isContentValid !== true) {
            isLanguageContentInValid = true
          }
          if (item.language_code === 'de') setDeErrors(isContentValid)
          if (item.language_code === 'en') setEnErrors(isContentValid)
          if (item.language_code === 'nl') setNlErrors(isContentValid)
          if (item.language_code === 'it') setItErrors(isContentValid)
          if (item.language_code === 'fr') setFrErrors(isContentValid)
          if (item.language_code === 'es') setEsErrors(isContentValid)
        }
      })
    }

    //target block validation
    const target_schema = yup.object().shape({
      target_audience: yup.object().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
    })
    const isTargetValid = await validateSchema({ target_audience: targetAudience }, target_schema)
    setTargetError(isTargetValid)

    //scheduler block validation
    let isSchedulerValid = false
    let isLifeSpanValid = false
    if (_.isEmpty(regionAndLanguageDetail.repeat_mode) || regionAndLanguageDetail.repeat_mode?.value !== ENUMS.REPEATE_MODE.BIRTHDAY) {
      const scheduler_schema = yup.object().shape({
        date:
          regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.ONE_TIME || regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.YEARLY || regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.MONTHLY
            ? !_.isEmpty(lifeSpanDetail.end_datetime)
              ? yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW).max(lifeSpanDetail.end_datetime, 'Date should not be greater than the end date')
              : yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW)
            : null,
        time: !_.isEmpty(regionAndLanguageDetail.repeat_mode) ? yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW) : null,
        week: regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.WEEKLY ? yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW) : null,
        month: regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.MONTHLY ? yup.array().min(1, ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW) : null,
      })
      isSchedulerValid = await validateSchema(pushNotificationDetail, scheduler_schema)
      setPushNotificationError(isSchedulerValid)
      //check if repeate mode is one time then It should be after 5 min
      if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.ONE_TIME && !_.isEmpty(pushNotificationDetail.date) && !_.isEmpty(pushNotificationDetail.time)) {
        const futureDateTime = currentDateTime.clone().add(5, 'minutes')
        const targetDateTime = moment(pushNotificationDetail.date.format('YYYY-MM-DD') + ' ' + pushNotificationDetail.time.format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
        if (targetDateTime.isBefore(futureDateTime)) {
          isSchedulerValid = false
          setPushNotificationError({ ...pushNotificationError, time: { error: true, msg: 'Please enter the desired time and ensure it is 5 minutes later than the current time' } })
        }
      }
      if (!_.isEmpty(lifeSpanDetail.end_datetime) && !_.isEmpty(pushNotificationDetail.date) && !_.isEmpty(pushNotificationDetail.time)) {
        const targetDateTime = moment(pushNotificationDetail.date.format('YYYY-MM-DD') + ' ' + pushNotificationDetail.time.format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
        if (targetDateTime.isAfter(lifeSpanDetail.start_datetime)) {
          isSchedulerValid = false
          setPushNotificationError({ ...pushNotificationError, date: { error: true, msg: 'The push notification date time should not be greater than the start date time' } })
        } else if (targetDateTime.isAfter(lifeSpanDetail.end_datetime)) {
          isSchedulerValid = false
          setPushNotificationError({ ...pushNotificationError, date: { error: true, msg: 'The push notification date time should not be greater than the end date time' } })
        }
      }

      //lifespan block validation
      const lifespan_schema = yup.object().shape({
        teaser_date: yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW).min(currentDateTime, 'The teaser date should be the current date').max(yup.ref('start_datetime'), 'The teaser date should not be greater than the start date'),
        start_datetime: yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW).min(currentDateTime, 'The start date should be the current date').max(yup.ref('end_datetime'), 'The start date should not be greater than the end date'),
        end_datetime: yup
          .date()
          .required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW)
          .min(lifeSpanDetail.start_datetime !== null ? lifeSpanDetail.start_datetime : currentDateTime, lifeSpanDetail.start_datetime !== null ? 'The end date should not be less than the start date' : 'The end date should be the current date'),
      })
      isLifeSpanValid = await validateSchema(lifeSpanDetail, lifespan_schema)
      setLifeSpanError(isLifeSpanValid)
    } else {
      isSchedulerValid = true
      isLifeSpanValid = true
      setPushNotificationError({})
      setLifeSpanError({})
    }

    let isNotifyBeforeValid = false
    if (regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.BIRTHDAY) {
      const notifybefore_schema = yup.object().shape({
        notify_before: yup.object().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
        birthday_notification_time: yup.date().required(ENUMS.COMMON_MSG.BLANK_FIELD_NOTALLOW),
      })
      isNotifyBeforeValid = await validateSchema(notifyBeforeDetail, notifybefore_schema)
      setNotifyBeforeError(isNotifyBeforeValid)
    } else {
      isNotifyBeforeValid = true
      setNotifyBeforeError({})
    }

    //if all block valid return true
    if (isRegionValid === true && isSchedulerValid === true && isLanguageContentInValid === false && isTargetValid === true && isLifeSpanValid === true && isNotifyBeforeValid === true) {
      return true
    } else {
      return false
    }
  }

  const setIsChangesMade = isChangesMade => {
    if (isViewOnly === false) {
      setIsDataChanged(isChangesMade)
      if (isChangesMade === false && localStorage.key('IsChangesMade').length) {
        localStorage.removeItem('IsChangesMade')
      } else {
        localStorage.setItem('IsChangesMade', isChangesMade)
      }
    } else {
      if (localStorage.key('IsChangesMade').length) {
        localStorage.removeItem('IsChangesMade')
      }
    }
  }

  useEffect(() => {
    const focusOnFirstError = (errorFields, language_code = null) => {
      const firstErrorField = Object.keys(errorFields).find(key => errorFields[key] !== '')

      if (firstErrorField) {
        let input_id = ''
        if (!_.isEmpty(language_code)) {
          input_id = language_code + '.' + firstErrorField
        } else {
          input_id = firstErrorField
        }
        const firstErrorElement = document.getElementById(input_id)
        setErrorTextFocus(firstErrorElement)
      }
    }
    if (!_.isEmpty(regionError)) {
      focusOnFirstError(regionError)
    } else if (!_.isEmpty(pushNotificationError)) {
      focusOnFirstError(pushNotificationError)
    } else if (!_.isEmpty(deErrors)) {
      focusOnFirstError(deErrors, 'de')
    } else if (!_.isEmpty(enErrors)) {
      focusOnFirstError(enErrors, 'en')
    } else if (!_.isEmpty(frErrors)) {
      focusOnFirstError(frErrors, 'fr')
    } else if (!_.isEmpty(targetError)) {
      focusOnFirstError(targetError)
    } else if (!_.isEmpty(lifeSpanError)) {
      focusOnFirstError(lifeSpanError)
    } else if (!_.isEmpty(notifyBeforeError)) {
      focusOnFirstError(notifyBeforeError)
    }
  }, [regionError, pushNotificationError, deErrors, enErrors, frErrors, targetError, lifeSpanError, notifyBeforeError])

  const setErrorTextFocus = input_element => {
    if (input_element && input_element.closest('.reactselect-dropdown-block')) {
      //for dropdown control
      const selectElement = input_element.closest('.reactselect-dropdown-block')
      const errorElement = selectElement.querySelector('.Mui-error')
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else if (input_element && input_element.classList.contains('language-chips-block') && input_element.nextElementSibling.classList.contains('Mui-error')) {
      //for language chip control
      const errorElement = input_element.nextElementSibling
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else if (input_element && input_element.classList.contains('datetime-pickers') && input_element.nextElementSibling.classList.contains('Mui-error')) {
      //for datetime related controls
      const errorElement = input_element.nextElementSibling
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else if (input_element && input_element.parentElement.nextElementSibling && input_element.parentElement.nextElementSibling.classList.contains('Mui-error')) {
      const errorElement = input_element.parentElement.nextElementSibling
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
  return (
    <Layout>
      <RingSpinnerOverlay loading={loading} overlayColor='rgba(0,0,0,0.2)' size={50} color='#2d38384d' borderWidth={3} />
      <Container component='div' maxWidth='xl' className='plr-0'>
        <Card component='div' sx={ENUMS.COMMON_STYLE.cardStyle}>
          <CardHeader title={<CardTitle />} className='template-cardheader' />
          <CardContent className='template-card-content-scroll theme-scroll-bar'>
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
              <CardHeader title='Regions' className='template-sub-cardheader' />
              <CardContent>
                <Grid container spacing={2} display={'flex'}>
                  <Grid item xs={4} className='reactselect-dropdown-block'>
                    <MinimalMultipleSelect
                      placeholder='Select Region'
                      value={regionAndLanguageDetail.regions}
                      options={[...ENUMS.COUNTRY_LIST]}
                      onInputChange={e => handleRegionAndLanguageInputChange('regions', e)}
                      onInputFocus={() => {
                        setRegionError({ ...regionError, regions: { error: false, msg: '' } })
                      }}
                      error={regionError.regions && regionError.regions.error}
                      helperText={regionError.regions && regionError.regions.msg}
                      id='regions'
                      disabled={isViewOnly || isRegionDisable}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
              <CardHeader title='Languages' className='template-sub-cardheader' />
              <CardContent>
                <Grid container spacing={2} display={'flex'}>
                  <Grid item xs={8}>
                    <div style={{ paddingTop: '10px' }} id='selected_language' className='language-chips-block'>
                      {country_codes.length &&
                        country_codes.map((item, key) => {
                          const activeClass = formContent.some(e => e.is_visible && e.language_code === item.value) ? ' active' : ''
                          const disableClass = isViewOnly && formContent.some(e => e.is_visible && e.language_code !== item.value) ? 'disabled' : ''
                          return <Chip avatar={<Avatar alt={item.value} src={item.image_src} />} label={item.label} variant='outlined' className={`flag-chip ${activeClass}`} sx={{ mr: 1 }} key={key} onClick={() => handleContentSelect(item.value)} disabled={isViewOnly} />
                        })}
                    </div>
                    {country_codes.length && (
                      <FormHelperText error={regionError.selected_language && regionError.selected_language.error} sx={{ marginLeft: '14px' }}>
                        {regionError.selected_language && regionError.selected_language.msg}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {(_.isEmpty(urlParams) || urlParams.id === 0) && (
              <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
                <CardHeader title='Templates' className='template-sub-cardheader' />
                <CardContent>
                  <Grid container spacing={2} display={'flex'}>
                    <Grid item xs={4}>
                      {(_.isEmpty(urlParams) || urlParams.id === 0) && (
                        <MinimalSingleSelect
                          placeholder='Select Template'
                          value={regionAndLanguageDetail.template}
                          options={templateList}
                          onInputChange={e => handleRegionAndLanguageInputChange('template', e)}
                          onInputFocus={() => {
                            setRegionError({ ...regionError, template: { error: false, msg: '' } })
                          }}
                          id='template'
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {formContent.some(item => item.is_visible === true) && (
              <Card sx={ENUMS.COMMON_STYLE.innerCardStyle}>
                <CardHeader title='Content' className='template-sub-cardheader' />
                <CardContent>
                  {formContent.map((item, key) => {
                    if (item.language_code === 'de' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for German'
                          errors={deErrors}
                          id_initials='de'
                          file_input_ref={fileUploadInputRefs.deInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                          disabled={isViewOnly}
                        />
                      )
                    if (item.language_code === 'en' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for English'
                          errors={enErrors}
                          id_initials='en'
                          file_input_ref={fileUploadInputRefs.enInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                          disabled={isViewOnly}
                        />
                      )
                    if (item.language_code === 'it' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for Italian'
                          errors={itErrors}
                          id_initials='it'
                          file_input_ref={fileUploadInputRefs.itInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                        />
                      )
                    if (item.language_code === 'fr' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for French'
                          errors={frErrors}
                          id_initials='fr'
                          file_input_ref={fileUploadInputRefs.frInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                          disabled={isViewOnly}
                        />
                      )
                    if (item.language_code === 'nl' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for French'
                          errors={nlErrors}
                          id_initials='nl'
                          file_input_ref={fileUploadInputRefs.nlInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                        />
                      )
                    if (item.language_code === 'es' && item.is_visible)
                      return (
                        <LanguageContentCard
                          title='Content for Spanish'
                          errors={nlErrors}
                          id_initials='es'
                          file_input_ref={fileUploadInputRefs.esInputRefs}
                          form_input_content={item}
                          onHandleFileSelect={handleFileSelect}
                          onHandleClearImage={() => clearImage(item.language_code)}
                          onInputChange={e => handleInputChange(e, item.language_code)}
                          onHandleFocus={e => handleInputFocus(e, item.language_code)}
                          key={key}
                        />
                      )
                  })}
                </CardContent>
              </Card>
            )}
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
              <CardHeader title='Target' className='template-sub-cardheader' />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4} className='reactselect-dropdown-block'>
                    <MinimalSingleSelect
                      placeholder='Select Target Audience'
                      value={targetAudience}
                      options={ENUMS.TARGET_AUDIENCE_LIST}
                      onInputChange={handleTargetChange}
                      error={targetError.target_audience && targetError.target_audience.error}
                      helperText={targetError.target_audience && targetError.target_audience.msg}
                      onInputFocus={() => {
                        setTargetError({ ...targetError, target_audience: { error: false, msg: '' } })
                      }}
                      info='The target audience comprises the device platforms, such as iOS and Android, for which the announcement is intended.'
                      id='target_audience'
                      disabled={isViewOnly}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
              <CardHeader title='Mode' className='template-sub-cardheader' />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4} className='reactselect-dropdown-block'>
                    <MinimalSingleSelect
                      placeholder='Select Repeat Mode'
                      value={regionAndLanguageDetail.repeat_mode}
                      options={repeatModeOptions}
                      onInputChange={e => handleRegionAndLanguageInputChange('repeat_mode', e)}
                      onInputFocus={() => {
                        setRegionError({ ...regionError, repeat_mode: { error: false, msg: '' } })
                      }}
                      error={regionError.repeat_mode && regionError.repeat_mode.error}
                      helperText={regionError.repeat_mode && regionError.repeat_mode.msg}
                      id='repeat_mode'
                      disabled={isViewOnly}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {!_.isEmpty(regionAndLanguageDetail.repeat_mode) && regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.BIRTHDAY && (
              <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
                <CardHeader title='Notify Before' className='template-sub-cardheader' />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} className='reactselect-dropdown-block'>
                      <MinimalSingleSelect
                        placeholder='Select Day'
                        value={notifyBeforeDetail.notify_before}
                        options={ENUMS.NOTIFYBEFORE_DAY_LIST}
                        onInputChange={e => handleNotifyBeforeChange('notify_before', e)}
                        error={notifyBeforeError.notify_before && notifyBeforeError.notify_before.error}
                        helperText={notifyBeforeError.notify_before && notifyBeforeError.notify_before.msg}
                        onInputFocus={() => {
                          setNotifyBeforeError({ ...notifyBeforeError, notify_before: { error: false, msg: '' } })
                        }}
                        id='notify_before'
                        disabled={isViewOnly}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MinimalTimePicker
                        value={notifyBeforeDetail.birthday_notification_time}
                        onInputChange={e => handleNotifyBeforeChange('birthday_notification_time', e)}
                        placeholder='Notification Time'
                        error={notifyBeforeError.birthday_notification_time && notifyBeforeError.birthday_notification_time.error}
                        helperText={notifyBeforeError.birthday_notification_time && notifyBeforeError.birthday_notification_time.msg}
                        onInputFocus={() => {
                          setNotifyBeforeError({ ...notifyBeforeError, birthday_notification_time: { error: false, msg: '' } })
                        }}
                        label='Notification Time'
                        id='birthday_notification_time'
                        disabled={isViewOnly}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            {!_.isEmpty(regionAndLanguageDetail.repeat_mode) && regionAndLanguageDetail.repeat_mode?.value !== ENUMS.REPEATE_MODE.BIRTHDAY && (
              <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle} style={{ overflow: 'visible' }}>
                <CardHeader title='Push Notification' className='template-sub-cardheader' />
                <CardContent>
                  <Grid container spacing={2}>
                    {regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.MONTHLY && (
                      <Grid item xs={4}>
                        <MinimalMultipleSelect
                          placeholder='Select Month'
                          value={pushNotificationDetail.month}
                          options={[...ENUMS.MONTH_LIST]}
                          onInputChange={e => handlePushNotificationDetailChange('month', e)}
                          error={pushNotificationError.month && pushNotificationError.month.error}
                          helperText={pushNotificationError.month && pushNotificationError.month.msg}
                          onInputFocus={() => {
                            setPushNotificationError({ ...pushNotificationError, month: { error: false, msg: '' } })
                          }}
                          id='month'
                          disabled={isViewOnly}
                        />
                      </Grid>
                    )}
                    {(regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.ONE_TIME || regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.YEARLY || regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.MONTHLY) && (
                      <Grid item xs={4}>
                        <MinimalDatePicker
                          value={pushNotificationDetail.date}
                          onInputChange={e => handlePushNotificationDetailChange('date', e)}
                          placeholder='Date'
                          error={pushNotificationError.date && pushNotificationError.date.error}
                          helperText={pushNotificationError.date && pushNotificationError.date.msg}
                          onInputFocus={() => {
                            setPushNotificationError({ ...pushNotificationError, date: { error: false, msg: '' } })
                          }}
                          minDate={currentDateTime}
                          onEditLoad={!_.isEmpty(urlParams) && urlParams.id !== 0 ? true : false}
                          label='Date'
                          maxDate={lifeSpanDetail.end_datetime}
                          id='date'
                          disabled={isViewOnly}
                        />
                      </Grid>
                    )}
                    {regionAndLanguageDetail.repeat_mode?.value === ENUMS.REPEATE_MODE.WEEKLY && (
                      <Grid item xs={4}>
                        <MinimalMultipleSelect
                          placeholder='Select Day'
                          value={pushNotificationDetail.week}
                          options={[...ENUMS.WEEK_LIST]}
                          onInputChange={e => handlePushNotificationDetailChange('week', e)}
                          error={pushNotificationError.week && pushNotificationError.week.error}
                          helperText={pushNotificationError.week && pushNotificationError.week.msg}
                          onInputFocus={() => {
                            setPushNotificationError({ ...pushNotificationError, week: { error: false, msg: '' } })
                          }}
                          id='week'
                          disabled={isViewOnly}
                        />
                      </Grid>
                    )}
                    {!_.isEmpty(regionAndLanguageDetail.repeat_mode) && (
                      <Grid item xs={4}>
                        <MinimalTimePicker
                          value={pushNotificationDetail.time}
                          onInputChange={e => handlePushNotificationDetailChange('time', e)}
                          placeholder='Time'
                          error={pushNotificationError.time && pushNotificationError.time.error}
                          helperText={pushNotificationError.time && pushNotificationError.time.msg}
                          onInputFocus={() => {
                            setPushNotificationError({ ...pushNotificationError, time: { error: false, msg: '' } })
                          }}
                          label='Time'
                          id='time'
                          disabled={isViewOnly}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            )}
            {regionAndLanguageDetail.repeat_mode?.value !== ENUMS.REPEATE_MODE.BIRTHDAY && (
              <Card component='div' sx={ENUMS.COMMON_STYLE.innerCardStyle}>
                <CardHeader title='Life Span' className='template-sub-cardheader' />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MinimalDateTimePicker
                        value={lifeSpanDetail.teaser_date}
                        onInputChange={e => handleLifeSpanDetailChange('teaser_date', e)}
                        placeholder='Teaser Date & Time'
                        error={lifeSpanError.teaser_date && lifeSpanError.teaser_date.error}
                        helperText={lifeSpanError.teaser_date && lifeSpanError.teaser_date.msg}
                        onInputFocus={() => {
                          setLifeSpanError({ ...lifeSpanError, teaser_date: { error: false, msg: '' } })
                        }}
                        minDateTime={currentDateTime}
                        maxDateTime={lifeSpanDetail.start_datetime}
                        label='Teaser Date & Time'
                        onEditLoad={!_.isEmpty(urlParams) && urlParams.id !== 0 ? true : false}
                        info='The teaser date is the designated date on which the target audience becomes able to view the announcement in an inactive state.'
                        id='teaser_date'
                        disabled={isViewOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <MinimalDateTimePicker
                        value={lifeSpanDetail.start_datetime}
                        onInputChange={e => handleLifeSpanDetailChange('start_datetime', e)}
                        placeholder='Start Date & Time'
                        error={lifeSpanError.start_datetime && lifeSpanError.start_datetime.error}
                        helperText={lifeSpanError.start_datetime && lifeSpanError.start_datetime.msg}
                        onInputFocus={() => {
                          setLifeSpanError({ ...lifeSpanError, start_datetime: { error: false, msg: '' } })
                        }}
                        minDateTime={currentDateTime}
                        maxDateTime={lifeSpanDetail.end_datetime}
                        onEditLoad={!_.isEmpty(urlParams) && urlParams.id !== 0 ? true : false}
                        info='The start date is the date from which the target audience can view the announcement in its active state.'
                        id='start_datetime'
                        disabled={isViewOnly}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                      <MinimalDateTimePicker
                        value={lifeSpanDetail.end_datetime}
                        onInputChange={e => handleLifeSpanDetailChange('end_datetime', e)}
                        placeholder='End Date & Time'
                        error={lifeSpanError.end_datetime && lifeSpanError.end_datetime.error}
                        helperText={lifeSpanError.end_datetime && lifeSpanError.end_datetime.msg}
                        onInputFocus={() => {
                          setLifeSpanError({ ...lifeSpanError, end_datetime: { error: false, msg: '' } })
                        }}
                        minDateTime={lifeSpanDetail.start_datetime !== null ? lifeSpanDetail.start_datetime : !_.isEmpty(urlParams) && urlParams.id !== 0 ? null : currentDateTime}
                        info='The end date is the date after which the target audience will no longer be able to view the announcement.'
                        id='end_datetime'
                        disabled={isViewOnly}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </CardContent>
          <Divider sx={ENUMS.COMMON_STYLE.dividerStyle} />
          <CardActions className='template-cardfooter' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Box>
              {checkUserRole.isStaff() && !isViewOnly && <FloatingButton text='Send for Review' baseClassName='common-btn' onClick={e => handleSaveOrReviewBtnClick(e, true)} sx={{ mr: 1 }} />}
              {!isViewOnly && <FloatingButton text='Save' baseClassName='common-btn' onClick={e => handleSaveOrReviewBtnClick(e)} />}
            </Box>
          </CardActions>
        </Card>
      </Container>
      <DialogPrompt
        open={showBackClickDialogue}
        onCancel={() => setShowBackClickDialogue(false)}
        onConfirm={() => {
          setIsChangesMade(false)
          navigate('/announcements')
        }}
        title='Clicking on the back, will discard your changes'
      />
    </Layout>
  )
}

export default AddAnnouncement
