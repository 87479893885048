import axios from 'axios'
import API_HELPER from '../constants/api-urls'
import { get as getPropertyValue, isEmpty, isArray } from 'lodash'
import { isJsonString } from '../helpers/common-helper'

function postApiCall(url, requestData, responseType = 'json', token = null) {
  return new Promise((resolve, reject) => {
    var apiUrl = API_HELPER.BASE + url
    if (url.includes('translation.googleapis.com')) {
      apiUrl = url
    }
    let headers = {}
    if (token !== null) {
      headers = {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      }
    } else {
      headers = {
        'Content-Type': 'application/json',
      }
    }
    const request = axios({
      method: 'POST',
      url: apiUrl,
      data: requestData,
      responseType: responseType,
      // timeout:1000000,
      headers: headers,
    })
    request
      .then(response => {
        let success_response = { success: true, data: response.data }
        resolve(success_response)
      })
      .catch(error => {
        if (error.request && (!error.request.status || error.request.status === 502)) {
          window.location.href = window.location.origin + '/ui/undermaintenance'
        } else if (error.response && error.response.status === 401) {
          window.location.href = window.location.origin + '/ui'
        } else {
          let errorMsg = !isEmpty(getPropertyValue(error, 'request.response', '')) ? getPropertyValue(error, 'request.response', '') : 'Something went wrong !'
          if (isJsonString(errorMsg)) {
            let actual_msg = JSON.parse(errorMsg)
            if (actual_msg.non_field_errors) {
              errorMsg = actual_msg.non_field_errors[0]
            } else if (actual_msg.detail) {
              if (isArray(actual_msg.detail)) {
                errorMsg = actual_msg.detail[0]
              } else {
                errorMsg = actual_msg.detail
              }
            }
          }
          let error_response = { success: false, error_message: errorMsg }
          reject(error_response)
        }
      })
  })
}

export default function post(url, requestData, token = null, responseType = 'json') {
  return new Promise((resolve, reject) => {
    let access_token = localStorage.getItem('AccessToken')
    if (token !== null) {
      access_token = token
    }
    postApiCall(url, requestData, responseType, access_token)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function getApiCall(url, token = null) {
  return new Promise((resolve, reject) => {
    var apiUrl = API_HELPER.BASE + url
    let headers = {}
    if (token !== null) {
      headers = {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      }
    } else {
      headers = {
        'Content-Type': 'application/json',
      }
    }
    const request = axios({
      method: 'GET',
      url: apiUrl,
      // timeout:1000000,
      headers: headers,
    })
    request
      .then(response => {
        let success_response = { success: true, data: response.data && response.data.data ? response.data.data : response.data }
        resolve(success_response)
      })
      .catch(error => {
        if (error.request && (!error.request.status || error.request.status === 502)) {
          window.location.href = window.location.origin + '/ui/undermaintenance'
        } else if (error.response && error.response.status === 401) {
          window.location.href = window.location.origin + '/ui'
        } else {
          let errorMsg = !isEmpty(getPropertyValue(error, 'request.response', '')) ? getPropertyValue(error, 'request.response', '') : 'Something went wrong !'
          if (isJsonString(errorMsg)) {
            let actual_msg = JSON.parse(errorMsg)
            if (actual_msg.non_field_errors) {
              errorMsg = actual_msg.non_field_errors[0]
            } else if (actual_msg.detail) {
              if (isArray(actual_msg.detail)) {
                errorMsg = actual_msg.detail[0]
              } else {
                errorMsg = actual_msg.detail
              }
            }
          }
          let error_response = { success: false, error_message: errorMsg }
          if (error.response.data && error.response.data.hasOwnProperty('status')) {
            error_response.status = error.response.data.status
          }
          reject(error_response)
        }
      })
  })
}

export function get(url, token = null) {
  return new Promise((resolve, reject) => {
    let access_token = localStorage.getItem('AccessToken')
    if (token !== null) {
      access_token = token
    }
    getApiCall(url, access_token)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function deleteApiCall(url, token) {
  return new Promise((resolve, reject) => {
    var apiUrl = API_HELPER.BASE + url
    let headers = {}
    headers = {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }
    const request = axios({
      method: 'DELETE',
      url: apiUrl,
      // timeout:1000000,
      headers: headers,
    })
    request
      .then(response => {
        let success_response = { success: true, data: response.data }
        resolve(success_response)
      })
      .catch(error => {
        if (error.request && (!error.request.status || error.request.status === 502)) {
          window.location.href = window.location.origin + '/ui/undermaintenance'
        } else if (error.response && error.response.status === 401) {
          window.location.href = window.location.origin + '/ui'
        } else {
          let errorMsg = !isEmpty(getPropertyValue(error, 'request.response', '')) ? getPropertyValue(error, 'request.response', '') : 'Something went wrong !'
          if (isJsonString(errorMsg)) {
            let actual_msg = JSON.parse(errorMsg)
            if (actual_msg.non_field_errors) {
              errorMsg = actual_msg.non_field_errors[0]
            } else if (actual_msg.detail) {
              if (isArray(actual_msg.detail)) {
                errorMsg = actual_msg.detail[0]
              } else {
                errorMsg = actual_msg.detail
              }
            }
          }
          let error_response = { success: false, error_message: errorMsg }
          reject(error_response)
        }
      })
  })
}

export function deleteAction(url) {
  return new Promise((resolve, reject) => {
    let access_token = localStorage.getItem('AccessToken')
    deleteApiCall(url, access_token)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}
