import * as React from 'react'
import { AppBar, Box, Toolbar, Typography, Menu, Container, Tooltip, MenuItem, ListItemIcon, Badge, IconButton } from '@mui/material'
import logo from '../../assets/images/logo.png'
import CampaignIcon from '@mui/icons-material/Campaign'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowDropDown, Article, Close } from '@mui/icons-material'
import getUserRole from '../../helpers/getuser-role'
import _ from 'lodash'
import { DialogPrompt } from '../common/other-component'
import { SkandikaContextData } from '../../context'
import ENUMS from '../../constants/enum'
import { FloatingButton } from '../common/form-control'

function Navbar() {
  const checkUserRole = new getUserRole()
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const isActive = value => (location.pathname === value ? 'active' : '')
  const logindata = JSON.parse(localStorage.getItem('UserDetail'))
  const [showBackClickDialogue, setShowBackClickDialogue] = React.useState(false)
  const [navigateToPath, setNavigateToPath] = React.useState('')
  const { contextData } = React.useContext(SkandikaContextData)
  const [isShowRefreshMessage, setShowRefreshMessage] = React.useState(false)

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleNavigation = (navigate_to = '') => {
    if (localStorage.key('IsChangesMade').length && localStorage.getItem('IsChangesMade') === 'true') {
      setNavigateToPath(navigate_to)
      setShowBackClickDialogue(true)
    } else {
      localStorage.removeItem('IsChangesMade')
      navigate(navigate_to)
    }
  }

  const confirmNavigation = () => {
    localStorage.removeItem('IsChangesMade')
    navigate(navigateToPath)
  }

  React.useEffect(() => {
    if (window.location.pathname === '/ui/announcements') {
      let inactivityTimeout

      const handleInactivity = () => {
        
        if (contextData.isAnnouncementExist) {
          setShowRefreshMessage(true)
        }
        else {
          resetInactivityTimeout()
        }
      }

      const resetInactivityTimeout = () => {       
        // Clear the existing timeout if it exists
        if (inactivityTimeout) {
          clearTimeout(inactivityTimeout)
        }

        // Set a new timeout for 2 minutes (120000 milliseconds)
        inactivityTimeout = setTimeout(handleInactivity, 120000)        
      }

      // Attach event listeners for user activity
      const handleActivity = () => {        
        resetInactivityTimeout()
      }

      // Attach event listeners
      window.addEventListener('mousemove', handleActivity)
      window.addEventListener('keypress', handleActivity)
      window.addEventListener('click', handleActivity)

      // Initial setup
      resetInactivityTimeout()

      // Clean up event listeners and clear timeout on component unmount
      return () => {
        window.removeEventListener('mousemove', handleActivity)
        window.removeEventListener('keypress', handleActivity)
        window.removeEventListener('click', handleActivity)

        // Clear the timeout to avoid memory leaks
        if (inactivityTimeout) {
          clearTimeout(inactivityTimeout)
        }
      }
    }
  }, [])

  const handleRefreshPage = () => {
    window.location.reload()
  }

  const closeRefreshToast = () => {
    setShowRefreshMessage(false)
  }

  return (
    <>
      {isShowRefreshMessage && (
        <AppBar position='fixed' className='toast-msg-appbar'>
          <Toolbar>
            <Typography variant='p' style={{ flexGrow: 1 }}>
              <b> Please refresh the page to see the latest content</b>
            </Typography>
            <FloatingButton
              text='Refresh'
              onClick={() => {
                handleRefreshPage()
              }}
              baseClassName='common-btn'
              style={{ marginRight: '5px' }}
            />
            <IconButton
              color='inherit'
              onClick={() => {
                closeRefreshToast()
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <AppBar position='static' className='' sx={{ backgroundColor: ENUMS.THEME.MAIN_COLOR, boxShadow: 'none' }}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <img src={logo} alt='Skandika' className='appbar-logo' onClick={() => window.location.reload()} />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
            <Box sx={{ flexGrow: 0, position: 'relative', marginRight: '15px' }} id='basic-button' aria-controls={Boolean(anchorElUser) ? 'basic-menu' : undefined} aria-haspopup='true' aria-expanded={Boolean(anchorElUser) ? 'true' : undefined} onClick={handleOpenUserMenu}>
              <Typography
                variant='p'
                noWrap
                component='p'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  color: 'inherit',
                  textDecoration: 'none',
                  justifyContent: 'flex-end',
                }}
              >
                {logindata && logindata.first_name + ' ' + logindata.last_name + (logindata.staff_user && !_.isEmpty(logindata.staff_user.role) && '(' + logindata.staff_user.role.name + ')')}
              </Typography>
              <Typography
                variant='p'
                noWrap
                component='p'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  color: 'inherit',
                  textDecoration: 'none',
                  justifyContent: 'flex-end',
                }}
              >
                {logindata && logindata.email}
              </Typography>
              <ArrowDropDown sx={{ position: 'absolute', right: '-15px', top: '10px' }} />
            </Box>
            <Menu
              sx={{ mt: '45px', color: ENUMS.THEME.MAIN_COLOR }}
              className='navigation-menu'
              id='basic-menu'
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className={isActive('/announcements')} onClick={() => handleNavigation('/announcements')}>
                <ListItemIcon>
                  {!_.isEmpty(contextData) && !_.isEmpty(contextData.pendingCountData) && contextData.pendingCountData.advertisements > 0 ? (
                    <Badge
                      badgeContent={contextData.pendingCountData.advertisements}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      sx={{ '& .MuiBadge-badge': { backgroundColor: '#FF0000', color: '#fff' } }}
                      max={5000}
                    >
                      <CampaignIcon />
                    </Badge>
                  ) : (
                    <CampaignIcon />
                  )}
                </ListItemIcon>
                <Typography textAlign='center' sx={{ textDecoration: 'none' }}>
                  Announcement
                </Typography>
              </MenuItem>
              {/* <Divider /> */}
              <MenuItem className={isActive('/templates')} onClick={() => handleNavigation('/templates')}>
                <ListItemIcon>
                  {!_.isEmpty(contextData) && !_.isEmpty(contextData.pendingCountData) && contextData.pendingCountData.templates > 0 ? (
                    <Badge
                      badgeContent={contextData.pendingCountData.templates}
                      color='error'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      sx={{ '& .MuiBadge-badge': { backgroundColor: '#FF0000', color: '#fff' } }}
                      max={5000}
                    >
                      <Article />
                    </Badge>
                  ) : (
                    <Article />
                  )}
                </ListItemIcon>
                <Typography textAlign='center' sx={{ textDecoration: 'none' }}>
                  Manage Template
                </Typography>
              </MenuItem>
              {checkUserRole.isAdmin() && (
                <MenuItem className={isActive('/users')} onClick={() => handleNavigation('/users')}>
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <Typography textAlign='center' sx={{ textDecoration: 'none' }}>
                    Members
                  </Typography>
                </MenuItem>
              )}
              <MenuItem className={isActive('/')} onClick={() => handleNavigation('/')}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <Typography textAlign='center' sx={{ textDecoration: 'none' }}>
                  Sign Out
                </Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <DialogPrompt open={showBackClickDialogue} onCancel={() => setShowBackClickDialogue(false)} onConfirm={() => confirmNavigation()} title='Clicking on the back, will discard your changes' />
    </>
  )
}
export default Navbar
