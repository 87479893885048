import { isEmpty } from 'lodash'
import ENUMS from '../constants/enum'

export const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export async function imageUrlToBase64(imageUrl) {
  // Create an image element
  const img = new Image()

  // Set up a cross-origin attribute to avoid security issues
  img.crossOrigin = 'anonymous'

  return new Promise((resolve, reject) => {
    // Define an onload callback to handle the loaded image
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // Set the canvas dimensions to match the image
      canvas.width = img.width
      canvas.height = img.height

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0)

      // Get the Base64-encoded image data
      const base64 = canvas.toDataURL('image/jpeg') // Change the format as needed

      // Resolve the promise with the Base64 data
      resolve(base64)
    }

    // Handle any error loading the image
    img.onerror = function () {
      reject(new Error('Failed to load the image.'))
    }

    // Set the image source to trigger the onload callback
    img.src = imageUrl
  })
}
export const isValidFileType = file => {
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png']
  return allowedFileTypes.includes(file.type)
}
export const setMultipleSelectedValue = (options = [], value = []) => {  
  let selectedValue = []
  if (!isEmpty(value)) {    
    selectedValue = options.filter(e => value.includes(typeof e.value === 'string' ? e.value.toUpperCase() : e.value))    
    if (options.length === options.filter(e => value.includes(typeof e.value === 'string' ? e.value.toUpperCase() : e.value)).length) {
      selectedValue.unshift(ENUMS.SELECTALL_OPTION)
    }
  }
  return selectedValue
}
